import en from '~/lang/en.json'
import de from '~/lang/de.json'
import { defineI18nConfig } from '#i18n'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: { en, de },
  warnHtmlMessage: false
}))
